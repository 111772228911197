import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withRouter } from 'react-router-dom';
import '../css/style.css';
import { withTranslation, Trans } from "react-i18next";
// import bannerLogo from '../images/logo-banner-2.png'; Example of image used


import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'


class CondicionesDeUso extends Component {

    state = {
        
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    render() {
        const { t } = this.props;
        return (
            <>

            <Header/>



                <div className="inner-page-title">
                    <div className="container">
                        <div className="section-title">
                            <h2>
                                <b>
                                    <Trans>{t('terms:Terms')}</Trans>
                                </b>
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="inner-page-content">

                    <div className="container">
                    <Trans>{t('terms:Content')}</Trans>
                    </div>

                </div>

            <Footer/>
            </>
        )
    }

}

export default withRouter(withTranslation('terms')(CondicionesDeUso));