import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { withRouter, Link } from "react-router-dom";
import "../css/style.css";
import Switch from "react-switch";
// import { Widget } from '@typeform/embed-react'
import axios from "axios";
import { Range } from "react-range";
import Footer from "../includes/Footer/Footer";
import Header from "../includes/Header/Header";
import { withTranslation, Trans } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles

import $ from "jquery";

class Coach extends Component {
  state = {
    values: [30],
    checked: false,
    price: 30,
    athletes: 5,
    currencyType: "$",
    country: "USA"
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    // Europe € ES ESP
    // MX$ MEX  USA

    this.getGeoInfo();
    AOS.init({
      offset: 140,
      duration: 700,
      easing: "ease-in",
      delay: 100,
      once: true
    });
  }
  handleOnChange = (value) => {
    this.setState({
      volume: value
    });
  };

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        let country = data.country_code_iso3,
          currencyType = "";
        let defaultPrice = this.state.price;
        switch (country) {
          case "MEX":
            currencyType = "MX$";
            defaultPrice = 600;
            break;
          case "ESP":
            currencyType = "€";
            defaultPrice = 25;
            break;
          default:
            currencyType = "$";
            defaultPrice = 30;
            break;
        }
        this.setState({
          country: data.country_code_iso3,
          currencyType: currencyType,
          price: defaultPrice
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleChange = (checked) => {
    console.log(checked);
    let price = this.state.price;
    console.log(typeof price);
    if (checked) {
      price = parseInt(price.toString().replace(",", "")) * 10;
    } else {
      price = parseInt(price.toString().replace(",", "")) / 10;
    }
    // price = price;
    // price = parseInt(price).toLocaleString();
    this.setState({ checked });
    this.setState({ price: price.toLocaleString() });
  };
  changeSliderValues = (val) => {
    let values = 0;
    let athletes = this.state.athletes;
    let isYearly = this.state.checked;
    let currency = this.state.currencyType;
    // / Europe € ES ESP
    // MX$ MEX  USA
    if (currency === "€") {
      switch (val[0]) {
        case 30:
          values = 25;
          athletes = 5;
          break;
        case 35:
          values = 45;
          athletes = 10;
          break;
        case 40:
          values = 75;
          athletes = 25;
          break;
        case 45:
          values = 125;
          athletes = 50;
          break;
        case 50:
          values = 199;
          athletes = 100;
          break;
        case 55:
          values = 375;
          athletes = 250;
          break;
        default:
        // code block
      }
    } else if (currency === "MX$") {
      switch (val[0]) {
        case 30:
          values = 600;
          athletes = 5;
          break;
        case 35:
          values = 1000;
          athletes = 10;
          break;
        case 40:
          values = 2400;
          athletes = 25;
          break;
        case 45:
          values = 4000;
          athletes = 50;
          break;
        case 50:
          values = 6000;
          athletes = 100;
          break;
        case 55:
          values = 11000;
          athletes = 250;
          break;
        default:
        // code block
      }
    } else {
      switch (val[0]) {
        case 30:
          values = 30;
          athletes = 5;
          break;
        case 35:
          values = 50;
          athletes = 10;
          break;
        case 40:
          values = 80;
          athletes = 25;
          break;
        case 45:
          values = 150;
          athletes = 50;
          break;
        case 50:
          values = 250;
          athletes = 100;
          break;
        case 55:
          values = 400;
          athletes = 250;
          break;
        default:
        // code block
      }
    }

    if (isYearly === true) {
      values = values + "" + 0;
    }
    values = parseInt(values).toLocaleString();
    console.log(values);
    this.setState({ values: val, price: values, athletes: athletes });
  };
  render() {
    const { t, i18n } = this.props;
    return (
      <>
        <Header />
        <div className="digital-section">
          <div className="container">
            <div className="digital-inner">
              <div
                className="section-title section-title-large text-center"
                data-aos="fade-up"
              >
                <h2>
                  <Trans>{t("coach:headerHeading")}</Trans>
                </h2>
              </div>
              <div className="section-desc text-center" data-aos="fade-up">
                <p>
                  <Trans>{t("coach:headerSubHeading")}</Trans>
                </p>
              </div>
              <div className="digital-form" data-aos="fade-up">
                {/* <div className="digital-form-inner">
                                    <input type="text" placeholder="escribe tu email" className="form-control"/>
                                </div> */}
                <a
                  href="https://dashboard.soloperformance.app/auth/register"
                  target="_blank"
                  rel="noreferrer"
                  type="submit"
                  className="btn btn-gradient"
                >
                  <Trans>{t("coach:demoButton")}</Trans>{" "}
                </a>
              </div>
              <div className="digital-form-info text-center" data-aos="fade-up">
                <p className="gradient-text-coach">
                  <Trans>{t("coach:freeTrial")}</Trans>
                </p>
              </div>
              <div className="digital-form-img text-center" data-aos="fade-up">
                {/* <video
                                    controls={false}
                                    autoPlay
                                    muted
                                    loop
                                    className="Video"
                                >
                                    <source src="./images/solo-video.mp4" type="video/mp4" />
                                </video> */}
                {/* https://www.youtube.com/embed/?origin=https%3A%2F%2Fwww.dejanosapoyarte.com&autoplay=0&autohide=1&modestbranding=1&rel=0&fs=1&showinfo=0&controls=1&disablekb=1&enablejsapi=1&iv_load_policy=3&loop=1&widgetid=1 */}
                <iframe
                  class="screen mute"
                  id="tv"
                  frameborder="0"
                  allowfullscreen="1"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  title="YouTube video player"
                  src="https://www.youtube.com/embed/o5GDInAKusk?rel=0&modestbranding=1&autohide=1&mute=1&showinfo=0&controls=1&autoplay=1&disablekb=1&enablejsapi=1&iv_load_policy=3&loop=1&widgetid=1"
                  data-gtm-yt-inspected-34108935_8="true"
                  data-gtm-yt-inspected-1_19="true"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="arrow-section">
          <div className="container">
            <div className="arrow-inner" data-aos="fade-up">
              <div className="arrow-img text-center">
                <img src="./images/arrowss.svg" className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div
          className="content-img-section content-img-section-01"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="content-img-inner">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content-img-left">
                    <div className="content-img-pic d-none">
                      <img
                        src="./images/MacBookPro2.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" data-aos="fade-up">
                  <div className="content-img-right">
                    <div className="content-img-data">
                      <div className="section-title">
                        <h4 className="gradient-text">
                          <Trans>{t("coach:Digitize")}</Trans>
                        </h4>
                        <h2>
                          <Trans>{t("coach:Workout")}</Trans>
                        </h2>
                      </div>
                      <div className="section-desc">
                        <p>
                          <Trans>{t("coach:Periodize")}</Trans>
                        </p>
                        {/* <br/> */}
                        <p>
                          <Trans>{t("coach:Database")}</Trans>
                        </p>
                      </div>
                      <div className="content-link-outer">
                        <a
                          href="https://dashboard.soloperformance.app/auth/register"
                          target="_blank"
                          rel="noreferrer"
                          className="btn-link btn-link-red"
                        >
                          <Trans>{t("coach:Register")}</Trans>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="dots-section" data-aos="fade-up">
          <div className="container">
            <div className="dots-img">
              <img src="./images/dots-img.svg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>

        <div
          className="content-img-section content-img-section-02"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="content-img-inner">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content-img-left">
                    <div className="content-img-data">
                      <div className="section-title" data-aos="fade-up">
                        <h4 className="gradient-text">
                          <Trans>{t("coach:IPROGRAM")}</Trans>
                        </h4>
                        <h2>
                          <Trans>{t("coach:AthleteApp")}</Trans>
                        </h2>
                      </div>
                      <div className="section-desc" data-aos="fade-up">
                        <p className="mb-3">
                          <Trans>{t("coach:Communicate")}</Trans>
                        </p>
                        <p>
                          <Trans>{t("coach:Synchronize")}</Trans>
                        </p>
                      </div>
                      <div className="banner-logos" data-aos="fade-up">
                        <ul>
                          <li>
                            <a
                              href="https://apps.apple.com/za/app/soloperformance/id1594480988"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="./images/AppStore.png"
                                className="img-fluid"
                                alt=""
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://play.google.com/store/apps/details?id=com.soloperformance.app"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="./images/PlayStore.png"
                                className="img-fluid"
                                alt=""
                              />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" data-aos="fade-up">
                  <div className="content-img-right">
                    <div className="content-img-pic d-none">
                      <img
                        src="./images/Red.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dots-section mar-b-50" data-aos="fade-up">
          <div className="container">
            <div className="dots-img">
              <img src="./images/dots-img.svg" className="img-fluid" alt="" />
            </div>
          </div>
        </div>
        <div
          className="content-img-section content-img-section-001"
          data-aos="fade-up"
        >
          <div className="container">
            <div className="content-img-inner">
              <div className="row">
                <div className="col-lg-6">
                  <div className="content-img-left">
                    <div className="content-img-pic d-none">
                      <img
                        src="./images/iPad-Mini.png"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6" data-aos="fade-up">
                  <div className="content-img-right">
                    <div className="content-img-data">
                      <div className="section-title">
                        <h4 className="gradient-text">
                          <Trans>{t("coach:ENTRENADORES")}</Trans>
                        </h4>
                        <h2>
                          <Trans>{t("coach:Modo")}</Trans>
                        </h2>
                      </div>
                      <div className="section-desc">
                        <p className="mb-3">
                          <Trans>{t("coach:Visualiza")}</Trans>
                        </p>
                        {/* <br/> */}
                        <p>
                          <Trans>{t("coach:Database")}</Trans>
                        </p>
                      </div>
                      <div className="content-link-outer">
                        <a
                          href="https://dashboard.soloperformance.app/auth/register"
                          target="_blank"
                          rel="noreferrer"
                          className="btn-link btn-link-red"
                        >
                          <Trans>{t("coach:Register")}</Trans>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="dots-section" data-aos="fade-up" >
                    <div className="container">
                        <div className="dots-img">
                            <img src="./images/dots-img.svg" className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div> */}
        <div className="market-section" data-aos="fade-up">
          <div className="container">
            <div className="market-inner">
              <div className="section-title text-center">
                <h4 className="gradient-text">
                  <Trans>{t("coach:MONETIZE")}</Trans>
                </h4>
                <h2>
                  <Trans>{t("coach:Welcome")}</Trans>
                </h2>
              </div>
              <div className="section-desc text-center">
                <p>
                  <Trans>{t("coach:Createyour")}</Trans>
                </p>
              </div>
              <div className="market-img">
                <img
                  src="./images/market-img.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pbox-section" data-aos="fade-up">
          <div className="container">
            <div className="pbox-inner">
              <div className="row">
                <div className="col-lg-6">
                  <div className="pbox-b">
                    <div className="pbox-b-inner">
                      <div className="pbox-title">
                        <h2>
                          <b>
                            <Trans>{t("coach:Massive")}</Trans>
                          </b>
                        </h2>
                        <h2 className="gradient-text">
                          <b>
                            <Trans>{t("coach:programs")}</Trans>
                          </b>
                        </h2>
                      </div>
                      <div className="pbox-desc">
                        <p>
                          <Trans>{t("coach:Sellyour")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="pbox-b">
                    <div className="pbox-b-inner">
                      <div className="pbox-title">
                        <h2>
                          <b>
                            <Trans>{t("coach:Individual")}</Trans>{" "}
                          </b>
                        </h2>
                        <h2 className="gradient-text">
                          <b>
                            <Trans>{t("coach:subscriptions")}</Trans>
                          </b>
                        </h2>
                      </div>
                      <div className="pbox-desc">
                        <p>
                          <Trans>{t("coach:Createindividual")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="pbox-b">
                    <div className="pbox-b-inner">
                      <div className="pbox-title">
                        <h2>
                          <b>
                            <Trans>{t("coach:Exercisepacks")}</Trans>
                          </b>
                        </h2>
                        <h2 className="gradient-text">
                          <b>
                            <Trans>{t("coach:forcoaches")}</Trans>
                          </b>
                        </h2>
                      </div>
                      <div className="pbox-desc">
                        <p>
                          <Trans>{t("coach:Become")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="pbox-b">
                    <div className="pbox-b-inner">
                      <div className="pbox-title">
                        <h2>
                          <b>
                            <Trans>{t("coach:Videoconferencia")}</Trans>
                          </b>
                        </h2>
                        <h2 className="gradient-text">
                          <b>
                            <Trans>{t("coach:bajodemanda")}</Trans>
                          </b>
                        </h2>
                      </div>
                      <div className="pbox-desc">
                        <p>
                          <Trans>{t("coach:Subetus")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid-section" data-aos="fade-up">
          <div className="container">
            <div className="grid-inner">
              <div className="section-title section-title-large text-center">
                <h2 className="gradient-text">
                  <Trans>{t("coach:Themost")}</Trans>
                </h2>
              </div>
              <div className="section-desc text-center" data-aos="fade-up">
                <p>
                  <Trans>{t("coach:Wehave")}</Trans>
                </p>
              </div>

              <div className="grid-box-outer" data-aos="fade-up">
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="grid-box">
                      <div className="grid-box-inner">
                        <div className="grid-box-img">
                          <img
                            src="./images/grid-1.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="grid-box-content">
                          <div className="grid-box-title">
                            <h4>
                              <a href="#">
                                <b>
                                  <Trans>{t("coach:PerformanceAI")}</Trans>
                                </b>
                                <br />
                                <span>
                                  <Trans>{t("coach:Structureddrills")}</Trans>
                                </span>
                              </a>
                            </h4>
                          </div>
                          <div className="grid-box-desc">
                            <p>
                              <Trans>{t("coach:AllSoloPerformance")}</Trans>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="grid-box">
                      <div className="grid-box-inner">
                        <div className="grid-box-img">
                          <img
                            src="./images/grid-2.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="grid-box-content">
                          <div className="grid-box-title">
                            <h4>
                              <a href="#">
                                <b>
                                  <Trans>
                                    {t("coach:KnowledgeCentralization")}
                                  </Trans>
                                </b>
                                <br />
                                <span>
                                  <Trans>
                                    {t("coach:Centralizedoverview")}
                                  </Trans>
                                </span>
                              </a>
                            </h4>
                          </div>
                          <div className="grid-box-desc">
                            <p>
                              <Trans>{t("coach:Weknow")}</Trans>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6">
                    <div className="grid-box">
                      <div className="grid-box-inner">
                        <div className="grid-box-img">
                          <img
                            src="./images/grid-3.png"
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="grid-box-content">
                          <div className="grid-box-title">
                            <h4>
                              <a href="#">
                                <b>
                                  <Trans>{t("coach:AthleteID")}</Trans>
                                </b>
                                <br />
                                <span>
                                  <Trans>{t("coach:Unifiedidentities")}</Trans>
                                </span>
                              </a>
                            </h4>
                          </div>
                          <div className="grid-box-desc">
                            <p>
                              <Trans>{t("coach:EachSPF")}</Trans>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn-grid-outer text-center" data-aos="fade-up">
                <a
                  href="https://dashboard.soloperformance.app/auth/register"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-main btn-gradient"
                >
                  <Trans>{t("coach:Register")}</Trans>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="price-section" data-aos="fade-up">
          <div className="container">
            <div className="price-inner">
              <div
                className="section-title section-title-white text-center"
                data-aos="fade-up"
              >
                <h2>
                  <Trans>{t("coach:Pricingof")}</Trans>
                </h2>
              </div>
              <div className="section-desc text-center" data-aos="fade-up">
                <p>
                  <Trans>{t("coach:Taxincluded")}</Trans>
                </p>
              </div>

              <div className="price-img text-center" data-aos="fade-up">
                <div className="row">
                  <div className="col-md-12">
                    {/* <p>Switch</p> */}
                    <label
                      htmlFor="small-radius-switch"
                      className="switch-label"
                    >
                      <Switch
                        checked={this.state.checked}
                        onChange={this.handleChange}
                        handleDiameter={0}
                        offColor="#414141"
                        onColor="#414141"
                        offHandleColor="#414141"
                        onHandleColor="#414141"
                        height={56}
                        width={210}
                        borderRadius={38}
                        className="switch-class"
                        // activeBoxShadow="0px 0px 1px 2px #fffc35"
                        uncheckedIcon={
                          <p className="yearly-unselected">
                            <Trans>{t("coach:Yearly-17%")}</Trans>
                          </p>
                        }
                        checkedIcon={
                          <p className="monthly-unselected">Monthly</p>
                        }
                        uncheckedHandleIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "54px",
                              width: "105px",
                              borderRadius: "35px",
                              backgroundColor: "white",
                              fontSize: 15
                            }}
                          >
                            <p className="monthly-selected">
                              <Trans>{t("coach:Monthly")}</Trans>
                            </p>
                          </div>
                        }
                        checkedHandleIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#414141",
                              fontSize: 15,
                              height: "54px",
                              width: "105px",
                              borderRadius: "35px",
                              backgroundColor: "white",
                              marginLeft: "-50px"
                            }}
                          >
                            <Trans>{t("coach:Yearly-17%")}</Trans>
                          </div>
                        }
                        // className="react-switch"
                        id="small-radius-switch"
                      />
                    </label>

                    {/* <Switch onChange={this.handleChange} checked={this.state.checked} /> */}
                  </div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 col-12">
                        <p className="slider-left-top">
                          <Trans>{t("coach:Howmany")}</Trans>
                        </p>
                      </div>
                      <div className="col-md-12 col-12 order-2 order-md-1">
                        <p className="slider-left-text mb-3">
                          {this.state.athletes}{" "}
                          <Trans>{t("coach:athletes")}</Trans>
                        </p>
                        <Range
                          step={5}
                          min={30}
                          max={55}
                          values={this.state.values}
                          onChange={(e, val) => this.changeSliderValues(e, val)}
                          renderTrack={({ props, children }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: "3px",
                                width: "83%",
                                backgroundColor: "#FFF",
                                margin: "auto"
                              }}
                            >
                              {children}
                            </div>
                          )}
                          renderThumb={({ props }) => (
                            // console.log(props)children
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                                backgroundColor: "#FFF"
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="col-md-12 col-12 order-1 order-md-2">
                        <p className="slider-right-value">
                          {this.state.currencyType}
                          {this.state.price}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="price-info-top text-center" data-aos="fade-up">
                <p>
                  <Trans>{t("coach:Ifyou")}</Trans>{" "}
                  <a href="mailto:info@soloperformance.app">
                    <Trans>{t("coach:clickhere")}</Trans>
                  </a>
                </p>
              </div>

              <div className="btn-price-outer text-center" data-aos="fade-up">
                <a
                  href="https://dashboard.soloperformance.app/auth/register"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-main btn-gradient"
                >
                  <Trans>{t("coach:Register")}</Trans>
                </a>
              </div>
              <div className="price-info text-center" data-aos="fade-up">
                <p>
                  <Trans>{t("coach:Enjoyyour")}</Trans>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="faq-section faq-section-2">
          <div className="container">
            <div className="faq-inner">
              <div className="section-title text-center" data-aos="fade-up">
                <h2>
                  <Trans>{t("coach:Faq´s")}</Trans>
                </h2>
              </div>

              <div className="faq-outer">
                <div id="accordion">
                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading1">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapse1"
                        aria-expanded="true"
                        aria-controls="collapse1"
                      >
                        <Trans>{t("coach:Howmuch")}</Trans>
                      </button>
                    </div>

                    <div
                      id="collapse1"
                      className="collapse show"
                      aria-labelledby="heading1"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:Wehavedifferent")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading2">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse2"
                        aria-expanded="false"
                        aria-controls="collapse2"
                      >
                        <Trans>{t("coach:Arethere")}</Trans>
                      </button>
                    </div>
                    <div
                      id="collapse2"
                      className="collapse"
                      aria-labelledby="heading2"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:Ofcourse")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading3">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse3"
                        aria-expanded="false"
                        aria-controls="collapse3"
                      >
                        <Trans>{t("coach:CanIupdate")}</Trans>
                      </button>
                    </div>
                    <div
                      id="collapse3"
                      className="collapse"
                      aria-labelledby="heading3"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:OfcourseIf")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading4">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse4"
                        aria-expanded="false"
                        aria-controls="collapse4"
                      >
                        <Trans>{t("coach:CanItry")}</Trans>
                      </button>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse"
                      aria-labelledby="heading4"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:Registerwith")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading5">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse5"
                        aria-expanded="false"
                        aria-controls="collapse5"
                      >
                        <Trans>{t("coach:Whatare")}</Trans>
                      </button>
                    </div>
                    <div
                      id="collapse5"
                      className="collapse"
                      aria-labelledby="heading5"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:Itwill")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading6">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse6"
                        aria-expanded="false"
                        aria-controls="collapse6"
                      >
                        <Trans>{t("coach:Howdoes")}</Trans>
                      </button>
                    </div>
                    <div
                      id="collapse6"
                      className="collapse"
                      aria-labelledby="heading6"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:Stopsearching")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading7">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse7"
                        aria-expanded="false"
                        aria-controls="collapse7"
                      >
                        <Trans>{t("coach:DoesSoloPerformance")}</Trans>
                      </button>
                    </div>
                    <div
                      id="collapse7"
                      className="collapse"
                      aria-labelledby="heading7"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:ThereAreCountries")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading8">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse8"
                        aria-expanded="false"
                        aria-controls="collapse8"
                      >
                        <Trans>{t("coach:Howdoesthe")}</Trans>
                      </button>
                    </div>
                    <div
                      id="collapse8"
                      className="collapse"
                      aria-labelledby="heading8"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:Ifyouarea")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading9">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse9"
                        aria-expanded="false"
                        aria-controls="collapse9"
                      >
                        <Trans>{t("coach:IsSoloPerformance")}</Trans>
                      </button>
                    </div>
                    <div
                      id="collapse9"
                      className="collapse"
                      aria-labelledby="heading9"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:Seriouslywe")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="card" data-aos="fade-up">
                    <div className="card-header" id="heading9">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#collapse9"
                        aria-expanded="false"
                        aria-controls="collapse9"
                      >
                        <Trans>{t("coach:WhatisSoloPerformance")}</Trans>
                      </button>
                    </div>
                    <div
                      id="collapse9"
                      className="collapse"
                      aria-labelledby="heading9"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <p>
                          <Trans>{t("coach:Wewereborn")}</Trans>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default withRouter(withTranslation("coach")(Coach));
// export default Coach;
