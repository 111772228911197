import React, { Component } from 'react';
import { withRouter,Link as ReactLink } from 'react-router-dom';
import '../../css/style.css';
import { withTranslation, Trans } from "react-i18next";
import i18n from '../../../i18n';
import { Dropdown } from 'semantic-ui-react';

// import 'semantic-ui-css/semantic.min.css';
const languages = {en: 'English',es: 'Spanish'};
const languagesDropdown = [
    {
        key:"es",
        text:"Spanish",
        value:"es",
        image:{avatar:true,src:'./images/es.png'}
    },
    {
        key:"en",
        text:"English",
        value:"en",
        image:{avatar:true,src:'./images/en.png'}
    }
]
let language = languages[localStorage.getItem('language') ? localStorage.getItem('language') : 'es'];
const changeLanguage = (lang) => {
    localStorage.setItem('language', lang);
    language = languages[lang];
    i18n.changeLanguage(lang);
    // window.location.reload();
};
const options = [
    { value: 'es', label: 'es' },
    { value: 'en', label: 'en' }
  ];
class Footer extends Component {
    state = {
        isMobMenuOn:"",
        lang:"es"
    }
   componentDidMount(){
    var lang = localStorage.getItem("language")
    // console.log(lang);
    this.setState({
        lang:lang
    })

   }
   selectLanguage = (e,data) =>{
        let val = data.value;
        // console.log(data);
        localStorage.setItem("language",val)
        this.setState({
            lang:val
        })
        localStorage.setItem('language', val);
        language = languages[val];
        i18n.changeLanguage(val);
    }
    render() {
        const { t } = this.props;
        let selectedLang = this.state.lang;
        return (
            <>
                <div className="mockup-section">
                    <div className="container">
                        <div className="mockup-inner">

                            <div className="section-title section-title-white">
                                <h2><Trans>{t('footer:WelcomeTo')}</Trans></h2>
                            </div>
                            <div className="mockup-btn-outer">
                                <a href="https://dashboard.soloperformance.app/auth/register" target="_blank" rel="noreferrer"className="btn-link btn-link-white"><Trans>{t('footer:Register')}</Trans></a>
                            </div>
                            <div className="mockup-box-outer">
                                <div className="row align-items-end">
                                    <div className="col-lg-3 col-md-4 col-4">
                                        <div className="mockup-box-left">
                                            <img src="./images/iPhone-13-Pro-Front.png" className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-lg-9 col-md-8 col-8">
                                        <div className="mockup-box-right">
                                            <img src="./images/MacBookPro.png" className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>





                <footer className="footer-section">


                    <div className="footer-box-section">
                        <div className="container">
                            <div className="footer-box-outer">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-6">
                                        <div className="footer-box">
                                            <div className="footer-box-inner">
                                                <div className="footer-logo">
                                                    <img src="./images/solo-performance1.svg" className="img-fluid" alt=""/>
                                                </div>
                                                <div className="footer-social mt-2">
                                                    <ul>
                                                        {/* <li><a href="#" target="_blank"><img src="./images/Facebook.svg" className="img-fluid" alt=""/></a></li> */}
                                                        <li><a href="#" target="_blank"><img src="./images/Instagram.svg" className="img-fluid" alt=""/></a></li>
                                                        <li><a href="#" target="_blank"><img src="./images/Linkedin.svg" className="img-fluid" alt=""/></a></li>
                                                        {/* <li><a href="#" target="_blank"><img src="./images/Twitter.svg" className="img-fluid" alt=""/></a></li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-12">
                                        <div className="footer-box">
                                            <div className="footer-box-inner">
                                                <h3><Trans>{t('footer:Legal')}</Trans></h3>
                                                <div className="footer-menu">
                                                    <ul>
                                                        <li><ReactLink to="/aviso-legal"><Trans>{t('footer:LegalWarning')}</Trans></ReactLink></li>
                                                        <li><ReactLink to="/condiciones-de-uso"><Trans>{t('footer:Terms')}</Trans></ReactLink></li>
                                                        <li><ReactLink to="/políticas-de-privacidad"><Trans>{t('footer:Privacy')}</Trans></ReactLink></li>
                                                        <li><ReactLink to="/políticas-de-cookies"><Trans>{t('footer:Cookies')}</Trans></ReactLink></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 col-12">
                                    <div className="footer-box">
                                            <div className="footer-box-inner">
                                                <h3><Trans>{t('footer:Subscribe')}</Trans></h3>
                                                <div className="footer-form">
                                                    <div className="footer-form-inner">
                                                        <input type="text" placeholder="write your email" className="form-control"/>
                                                        <button type="submit" className="btn btn-red"><img src="./images/akar-icons-arrow-up-right.svg" className="img-fluid" alt=""/></button>
                                                    </div>
                                                    <br/>
                                                    <div>
                                                        <Dropdown
                                                            onChange={this.selectLanguage}
                                                            inline
                                                            options={languagesDropdown}
                                                            value={selectedLang ==='es'? languagesDropdown[0].value:languagesDropdown[1].value}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="footer-copy">
                                <p><Trans>{t('footer:Copyright')}</Trans> <b><a className="footer-link" href="https://bla.agency/" target="_blank" rel="noreferrer">Bla. Agency</a></b></p>
                            </div>
                        </div>
                    </div>








                </footer>
            </>
        )
    }

}


/*$(document).ready(function(){

    $("header ul.navbar-nav li a").click(function(){
        if($('.navbar-collapse').hasClass('show')){
            $('.navbar-collapse').removeClass("show");
            $('.navbar-toggler').addClass("collapsed");
            $('.navbar-toggler').attr("aria-expanded","false");
        }else{
            $('.navbar-collapse').addClass("hide");
        }
    });

});*/


export default withRouter(withTranslation('footer')(Footer));