import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { withRouter,Link } from 'react-router-dom';
import '../css/style.css';
import { withTranslation, Trans } from "react-i18next";


import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'



import $ from 'jquery';

class AvisoLegal extends Component {

    state = {
        
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

    } 
    render() {
        const { t, i18n } = this.props;
        return (
            <>

            <Header/>



                <div className="inner-page-title">
                    <div className="container">
                        <div className="section-title">
                            <h2><b>
                                <Trans>{t('avisolegal:AvisoLegal')}</Trans>
                                </b></h2>
                        </div>
                    </div>
                </div>

                <div className="inner-page-content">

                    <div className="container">
                    <Trans>{t('avisolegal:Content')}</Trans>
                    </div>

                </div>

            <Footer/>
            </>
        )
    }

}


// export default AvisoLegal;
export default withRouter(withTranslation('avisolegal')(AvisoLegal));