import React, { Component } from 'react';
// import {HashLink as NavLink } from 'react-router-hash-link';
// import { Link, animateScroll as scroll } from "react-scroll";
import { withRouter,NavLink as ReactLink } from 'react-router-dom';
import i18n from '../../../i18n';
import { withTranslation, Trans } from "react-i18next";
// import Select from 'react-select';
import '../../css/style.css';
// import { Dropdown } from 'semantic-ui-react';
// import 'semantic-ui-css/semantic.min.css';
// const languages = {en: 'English',es: 'Spanish'};
// const languagesDropdown = [
//     {
//         key:"es",
//         text:"Spanish",
//         value:"es",
//         image:{avatar:true,src:'./images/es.png'}
//     },
//     {
//         key:"en",
//         text:"English",
//         value:"en",
//         image:{avatar:true,src:'./images/en.png'}
//     }
// ]
// let language = languages[localStorage.getItem('language') ? localStorage.getItem('language') : 'es'];
// const changeLanguage = (lang) => {
//     localStorage.setItem('language', lang);
//     language = languages[lang];
//     i18n.changeLanguage(lang);
//     // window.location.reload();
// };
// const options = [
//     { value: 'es', label: 'es' },
//     { value: 'en', label: 'en' }
//   ];
class Header extends Component {
    state = {
        isMobMenuOn:"",
        lang:"es"
    }
   componentDidMount(){
    var lang = localStorage.getItem("language")
    this.setState({
        lang:lang
    })

   }
    // selectLanguage = (e,data) =>{
    //     let val = data.value;
    //     console.log(data);
    //     localStorage.setItem("language",val)
    //     this.setState({
    //         lang:val
    //     })
    //     localStorage.setItem('language', val);
    //     language = languages[val];
    //     i18n.changeLanguage(val);
    // }
    render() {
        const { t } = this.props;
        // let selectedLang = this.state.lang;
        return (
            <>

                <header>

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-12">
                                <nav className="navbar navbar-expand-lg navbar-light">

                                    <ReactLink className="navbar-brand logo" to=""><img src="./images/solo-performance1.svg" className="img-fluid" alt="" /></ReactLink>

                                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                            <span className="navbar-toggler-icon"></span>
 
                                            <img src="./images/toggle-icon.svg" className="menu-icon toggle-icon"/>
                                            <img src="./images/close-icon.svg" className="menu-icon close-icon"/>
                                        </button>

                                        <div className={`collapse navbar-collapse ${this.state.isMobMenuOn}`}id="navbarSupportedContent">
                                            <ul className="navbar-nav">
                                                <li><ReactLink exact activeClassName="active" to="athlete"><Trans>{t('header:Athlete')}</Trans></ReactLink></li>
                                                <li><ReactLink exact activeClassName="active" to="/"><Trans>{t('header:Coach')}</Trans></ReactLink></li>
                                                <li className="btn btn-pink"><a id="btn-color" href="https://dashboard.soloperformance.app/" target="_blank" rel="noreferrer"><Trans>{t('header:Session')}</Trans></a></li>
                                                <li className="btn btn-red"><a href="https://dashboard.soloperformance.app/auth/register/" target="_blank" rel="noreferrer"><Trans>{t('header:Register')}</Trans></a></li>
                                                {/* <li className="">
                                                    <Dropdown
                                                        // onChange={this.selectLanguage}
                                                        onChange={this.selectLanguage}
                                                        inline
                                                        options={languagesDropdown}
                                                        value={selectedLang ==='es'? languagesDropdown[0].value:languagesDropdown[1].value}
                                                    />
                                                </li> */}
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                        </div>








                    </header>



            </>
        )
    }

}

export default withRouter(withTranslation('header')(Header));
// export default Header;