import './App.css';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './components/Home/Home';
import Coach from './components/Coach/Coach';
import AvisoLegal from './components/AvisoLegal/AvisoLegal';
import CondicionesDeUso from './components/CondicionesDeUso/CondicionesDeUso';
import PolíticasDePrivacidad from './components/PolíticasDePrivacidad/PolíticasDePrivacidad';
import PolíticaDeCookies from './components/PolíticaDeCookies/PolíticaDeCookies';
import i18n from "./i18n";
function App() {
  let lang = localStorage.getItem('language');
  i18n.changeLanguage(lang);
  // const history = useHistory();
  const handleSocialLogin = (user) => {
    // console.log(user);
    localStorage.setItem('access_token', user._token.accessToken);
    localStorage.setItem('user_profile', JSON.stringify(user._profile));
    // history.replace(`/create-account`);
    window.location.replace('/create-account');
    // axios.post('/user/social-login', user._profile).then((response) => {
    //
    // });

  };

  const handleSocialLoginFailure = (err) => {
      console.error(err)
  };
  return (
    <Router>
      <Switch> 
        <Route exact path="/athlete" name="Athlete" component={Home} />
        <Route exact path="/" name="Coach" component={Coach} />
        <Route exact path="/aviso-legal" name="AvisoLegal" component={AvisoLegal} />
        <Route exact path="/condiciones-de-uso" name="CondicionesDeUso" component={CondicionesDeUso} />
        <Route exact path="/políticas-de-privacidad" name="PolíticasDePrivacidad" component={PolíticasDePrivacidad} />
        <Route exact path="/políticas-de-cookies" name="PolíticaDeCookies" component={PolíticaDeCookies} />
        {/* <Route exact path="/coach" name="Coach" component={Coach} /> */}
      </Switch>
    </Router>
  );
}

export default App;
