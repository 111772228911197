import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';

// import english from './translations/en/coach.json';
import coach from './translations/en/coach.json';
import headerTrans from './translations/en/header.json';
import footer from './translations/en/footer.json';
import home from './translations/en/home.json';
import avisolegal from './translations/en/avisolegal.json';
import terms from './translations/en/terms.json';
import privacy from './translations/en/privacy.json';
import cookies from './translations/en/cookies.json';

import headerTransEs from './translations/es/header.json';
import footerEs from './translations/es/footer.json';
import coachEs from './translations/es/coach.json';
import homeEs from './translations/es/home.json';
import privacyEs from './translations/es/privacy.json';
import cookiesEs from './translations/es/cookies.json';

// let data = {};
// axios.get('/all-translations').then(response => {
//     data = response.data;
//     console.log(data);
//
// });

i18n.use(HttpApi).init({
    // we init with resources
    // backend: {
    //     loadPath: 'http://localhost:8000/api/all-translations/{{lng}}',
    //     allowMultiLoading: false
    // },
    resources: {
        en: {
            header: headerTrans,
            footer:footer,
            coach:coach,
            home:home,
            avisolegal:avisolegal,
            terms:terms,
            privacy:privacy,
            cookies:cookies
        },
        es: {
            header: headerTransEs,
            footer:footerEs,
            coach:coachEs,
            home:homeEs,
            privacy:privacyEs,
            cookies:cookiesEs
        },
    },
    lng: "en",
    fallbackLng: "en",
    debug: true,
    // have a common namespace used around the full app
    ns: ["headerTrans", "footer", "coach", "home", "avisolegal", "terms", "privacy", "cookies", "nutritional", "beverage", "rareoil", "element"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ','
    },

    react: {
        wait: true,
        useSuspense: false,
        bindI18n: 'languageChanged',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i','b','span','h3','p'],
    }
});

export default i18n;