import React, { Component } from 'react';
import { withRouter,Link as ReactLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, animateScroll as scroll } from "react-scroll";
import '../css/style.css';
import '../css/modal-video.css';
import ModalVideo from 'react-modal-video'

import Footer from '../includes/Footer/Footer'
import Header from '../includes/Header/Header'
import { withTranslation, Trans } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles


import $ from 'jquery';

class Home extends Component {

    state = {
        cookiesPopup:sessionStorage.getItem("isCookies"),
        showPopup:false,
        isOpen: false
    };
    componentDidMount() {
       
        window.scrollTo(0,0);

        if(sessionStorage.getItem("isCookies") === null){
            this.setState({
                cookiesPopup:"show"
            })
        }
        setTimeout(() => {
            this.setState({showPopup: true});
          }, 5000)

            if ($('.left-scroll-outer').height() > $('.custom-height').height()) {
                setInterval(()=>{
                    this.start();
                },10) 
            }
            if ($('.right-scroll-outer').height() > $('.custom-height').height()) {
                setInterval(()=>{
                    this.start();
                },10) 
            }
            AOS.init({
                offset: 140,
                duration: 700,
                easing: 'ease-in',
                delay: 100,
                once:true
              });

    } 
    hideCookies =() => {
        sessionStorage.setItem("isCookies","hide")
        this.setState({
            cookiesPopup:"hide"
        })
    }
    hidePopup =() => {
        this.setState({
            showPopup:false
        })
    }
    openModal = () => {
        this.setState({isOpen: true})
      }

      

     animateContent = (direction) => {  
            var animationOffset = $('.custom-height').height() - $('.left-scroll-outer').height()-30;
            var animationOffset = $('.custom-height').height() - $('.right-scroll-outer').height()-30;
            animationOffset = 550;
            // animationOffset1 = 550;
            if (direction === 'up') {
                animationOffset = 0;
            }
            // $('.left-scroll-outer').animate({ "marginTop": (animationOffset)+ "px" }, 5000);
            $('.left-scroll-outer').animate({ "scrollTop": (animationOffset)+ "px" }, 9000);
            $('.right-scroll-outer').animate({ "scrollTop": (animationOffset)+ "px" }, 8800);
        }

        up = () => {
            this.animateContent("up")
        }
        down = () => {
            this.animateContent("down")
        }

        start = () => {
            setTimeout(
                () => this.down(), 
                1500
            );
            setTimeout(
                () => this.up(), 
                1500
            );
    }
    render() {
        const { t, i18n } = this.props;
        let cookiesPopupView = null, homePopup = null;
        if(this.state.cookiesPopup===null || this.state.cookiesPopup==="show"){
            cookiesPopupView = ( <div className="cookies-popup-section">
            <div className="container container-big">
                <div className="cookies-inner">
                    <div className="row">
                        <div className="col-lg-9">
                            <div className="cookies-popup-left">
                                <div className="cookies-content">
                                    <p><Trans>{t('home:Copyright')}</Trans>By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="cookies-popup-right">
                                <div className="cookies-popup-btn-outer">
                                    <a href="#" className="cookies-link"><Trans>{t('home:Copyright')}</Trans>Cookie Setting</a>
                                    <a onClick={this.hideCookies} className="cookies-btn"><Trans>{t('home:Copyright')}</Trans>Accept All Cookies</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
        
        }
        if(this.state.showPopup===null || this.state.showPopup===true){
            homePopup = ( 
                <div className="popup-section">
                    <div className="popup-inner">
                        <div className="popup-close">
                            <a onClick={this.hidePopup} className="hover-cursor"><img src="./images/Frame28.svg" classNameName="img-fluid pic" alt=""/></a>
                        </div>
                        <div className="popup-content">
                            <div className="popup-title">
                                <h2><Trans>{t('home:Copyright')}</Trans>Sorteo Ibiza</h2>
                            </div>
                            <div className="popup-desc">
                                <p><Trans>{t('home:Copyright')}</Trans>Disfruta de una experiencia única conectando con la naturaleza y los sentidos en la isla de Ibiza.</p>

                                <p><Trans>{t('home:Copyright')}</Trans>¿Quieres conseguir 1 entrada doble (alojamiento incluido) al evento de Ibiza? Solo tienes que contestar a las siguientes preguntas y entrarás directamente en el sorteo de una entrada doble para ti y quien tú quieras. </p>
                            </div>
                            <div className="popup-btn">
                                <ReactLink to="sorteo" className="btn btn-white"><span><Trans>{t('home:Copyright')}</Trans>Participar</span></ReactLink>
                            </div>
                        </div>
                    </div>
                </div>
        );
        }
        return (
            <>

            <Header/>
            {/* {homePopup} */}
            {/* {cookiesPopupView} */}

                <div className="banner-section" data-aos="fade-up">
                    <div className="container">
                        <div className="banner-inner">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="banner-left">
                                        <div className="banner-left-inner">
                                            <div className="banner-content">
                                                <div className="banner-title" data-aos="fade-up">
                                                    <h1 className="gradient-text"><Trans>{t('home:Trainlike')}</Trans></h1>
                                                </div>
                                                <div className="banner-desc" data-aos="fade-up">
                                                    <p>
                                                    <Trans>{t('home:Connectwith')}</Trans>
                                                    <br/>
                                                    <br/>
                                                    <Trans>{t('home:TrainingPrograms')}</Trans></p>
                                                </div>
                                                <div className="banner-logos" data-aos="fade-up">
                                                    <ul>
                                                        <li><a href="https://apps.apple.com/za/app/soloperformance/id1594480988" target="_blank" rel="noreferrer"><img src="./images/AppStore.png" className="img-fluid" alt=""/></a></li>
                                                        <li><a href="https://play.google.com/store/apps/details?id=com.soloperformance.app" target="_blank" rel="noreferrer"><img src="./images/PlayStore.png" className="img-fluid" alt=""/></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" data-aos="fade-up">
                                    <div className="banner-right">
                                        <div className="banner-right-inner">
                                            <div className="banner-img">
                                                <img src="./images/Red.png" className="img-fluid" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dots-section" data-aos="fade-up">
                    <div className="container">
                        <div className="dots-img">
                            <img src="./images/dots-img.svg" className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>

                <div className="content-img-section content-img-section-1" data-aos="fade-up">
                    <div className="container">
                        <div className="content-img-inner">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="content-img-left" data-aos="fade-up">
                                        <div className="content-img-pic d-none">
                                            <img src="./images/MacBookPro17.png" className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="content-img-right" data-aos="fade-up">
                                        <div className="content-img-data">
                                            <div className="section-title">
                                                <h4><Trans>{t('home:LookingFor')}</Trans></h4>
                                                <h2><Trans>{t('home:TrainingProgramsMarketplace')}</Trans></h2>
                                            </div>
                                            <div className="section-desc">
                                                <p><Trans>{t('home:Findspecialized')}</Trans></p>
                                                <br/>
                                                <p><Trans>{t('home:Downloadthe')}</Trans></p>
                                            </div>
                                            <div className="content-link-outer">
                                                <a href="#" className="btn-link btn-link-red"><Trans>{t('home:Comingsoon')}</Trans></a>
                                                {/* <a href="https://dashboard.soloperformance.app/auth/register" target="_blank" rel="noreferrer" className="btn-link btn-link-red">Descárgate la app</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dots-section" data-aos="fade-up">
                    <div className="container">
                        <div className="dots-img">
                            <img src="./images/dots-img.svg" className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>


                <div className="content-img-section content-img-section-2" data-aos="fade-up">
                    <div className="container">
                        <div className="content-img-inner">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="content-img-left">
                                        <div className="content-img-data">
                                            <div className="section-title" data-aos="fade-up">
                                                <h4><Trans>{t('home:ExclusivePerformance')}</Trans></h4>
                                                <h2><Trans>{t('home:ConnectWith')}</Trans></h2>
                                            </div>
                                            <div className="section-desc" data-aos="fade-up">
                                                <p><Trans>{t('home:LookingForIndividualized')}</Trans></p>
                                                <br/>
                                                <p><Trans>{t('home:FindAndConnect')}</Trans></p>
                                            </div>
                                            <div className="content-link-outer" data-aos="fade-up">
                                                <a href="#" className="btn-link btn-link-red"><Trans>{t('home:Comingsoon')}</Trans></a>
                                                {/* <a href="https://dashboard.soloperformance.app/auth/register" target="_blank" rel="noreferrer" className="btn-link btn-link-red">Regístrate gratis</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="content-img-right" data-aos="fade-up">
                                        <div className="content-img-pic d-none">
                                            <img src="./images/iPadPro.png" className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="dots-section" data-aos="fade-up">
                    <div className="container">
                        <div className="dots-img">
                            <img src="./images/dots-img.svg" className="img-fluid" alt=""/>
                        </div>
                    </div>
                </div>


                <div className="content-img-section content-img-section-3" data-aos="fade-up">
                    <div className="container">
                        <div className="content-img-inner">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="content-img-left" data-aos="fade-up">
                                        <div className="content-img-pic d-none">
                                            <img src="./images/iPhone-14.png" className="img-fluid" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="content-img-right" data-aos="fade-up">
                                        <div className="content-img-data">
                                            <div className="section-title">
                                                <h4><Trans>{t('home:TRAININGALGO')}</Trans></h4>
                                                <h2><Trans>{t('home:AICOACH')}</Trans>
                                                </h2>
                                            </div>
                                            <div className="section-desc">
                                                <p><Trans>{t('home:ReceiveMonthly')}</Trans>
                                                </p>
                                                <br/>
                                                <p>
                                                    <Trans>{t('home:Composition')}</Trans>
                                                    <span className='bar'>|</span>
                                                    <Trans>{t('home:Strength')}</Trans>
                                                    <span className='bar'>|</span>
                                                    <Trans>{t('home:Mobility')}</Trans>
                                                    <span className='bar'>|</span>
                                                    <Trans>{t('home:Hypertrophy')}</Trans>
                                                    <span className='bar'>|</span>
                                                    <Trans>{t('home:ExplosivenessMovement')}</Trans>
                                                    <span className='bar'>|</span>
                                                    <Trans>{t('home:VerticalJump')}</Trans>
                                                </p>
                                            </div>
                                            <div className="content-link-outer">
                                                <a href="#" className="btn-link btn-link-red"><Trans>{t('home:Comingsoon')}</Trans></a>
                                                {/* <a href="https://dashboard.soloperformance.app/auth/register" target="_blank" rel="noreferrer" className="btn-link btn-link-red">Descárgate la app</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="post-section" data-aos="fade-up">
                    <div className="container">
                        <div className="post-inner">
                            <div className="section-title section-title-large text-center" data-aos="fade-up">
                                <h2><Trans>{t('home:FindYour')}</Trans></h2>
                                <h2 className="gradient-text"><Trans>{t('home:PerformanceCoach')}</Trans></h2>
                            </div>
                            <div className="post-box-outer" data-aos="fade-up">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6">
                                        <div className="post-box">
                                            <div className="post-box-inner" data-aos="fade-up">
                                                <div className="post-box-img">
                                                    <img src="./images/post-1.png" className="img-fluid" alt=""/>
                                                </div>
                                                <div className="post-box-content">
                                                    <div className="post-box-tag">
                                                        <ul>
                                                            <li><a href="#"><Trans>{t('home:Performance')}</Trans></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="post-box-title">
                                                        <p><a href="#"><Trans>{t('home:RaiseYour')}</Trans></a></p>
                                                    </div>
                                                    <div className="post-box-desc">
                                                        <p><Trans>{t('home:ThoseWho')}</Trans></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="post-box">
                                            <div className="post-box-inner" data-aos="fade-up">
                                                <div className="post-box-img">
                                                    <img src="./images/post-2.png" className="img-fluid" alt=""/>
                                                </div>
                                                <div className="post-box-content">
                                                    <div className="post-box-tag">
                                                        <ul>
                                                            <li className="lesiones"><a href="#"><Trans>{t('home:Injuries')}</Trans></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="post-box-title">
                                                        <p><a href="#"><Trans>{t('home:RehabYour')}</Trans></a></p>
                                                    </div>
                                                    <div className="post-box-desc">
                                                        <p><Trans>{t('home:IfYourGoal')}</Trans></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6">
                                        <div className="post-box">
                                            <div className="post-box-inner" data-aos="fade-up">
                                                <div className="post-box-img">
                                                    <img src="./images/post-3.png" className="img-fluid" alt=""/>
                                                </div>
                                                <div className="post-box-content">
                                                    <div className="post-box-tag">
                                                        <ul>
                                                            <li className="salud"><a href="#"><Trans>{t('home:Health')}</Trans></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="post-box-title">
                                                        <p><a href="#"><Trans>{t('home:CareYourBody')}</Trans></a></p>
                                                    </div>
                                                    <div className="post-box-desc">
                                                        <p><Trans>{t('home:IfYouHave')}</Trans></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="program-section" data-aos="fade-up">
                    <div className="container">
                        <div className="program-inner">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <div className="program-left" data-aos="fade-up">
                                        <div className="section-title">
                                            <h2><Trans>{t('home:WorkoutRoutines')}</Trans></h2>
                                        </div>
                                        <div className="section-desc" data-aos="fade-up">
                                            <p>
                                            <Trans>{t('home:HundredOf')}</Trans>
                                            </p>
                                        </div>
                                        <div className="program-btn-outer" data-aos="fade-up">
                                            <a href="#" className="btn btn-red"><Trans>{t('home:Comingsoon')}</Trans></a>
                                            {/* <a href="https://dashboard.soloperformance.app/auth/register" target="_blank" rel="noreferrer" className="btn btn-red">Regístrate</a> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row custom-row">
                                        <div className="col-lg-6 col-6 custom-height" data-aos="fade-up">
                                            <div className="shadow-seciton shadow-seciton-left">
                                            <div className="left-scroll-outer">
                                                <div className="scroll-box-outer">
                                                    <div className="scroll-box">
                                                        <div className="scroll-box-inner">
                                                            <div className="scroll-box-img">
                                                                <img src="./images/Frame-623.png" className="img-fluid" alt=""/>
                                                            </div>
                                                            <div className="scroll-box-content">
                                                                <h5><Trans>{t('home:ReturnToPlay')}</Trans></h5>
                                                                <p><Trans>{t('home:BuyFor€')}</Trans></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="scroll-box">
                                                        <div className="scroll-box-inner">
                                                            <div className="scroll-box-img">
                                                                <img src="./images/sc-1.jpeg" className="img-fluid" alt=""/>
                                                            </div>
                                                            <div className="scroll-box-content">
                                                                <h5><Trans>{t('home:GetStronger')}</Trans></h5>
                                                                <p><Trans>{t('home:BuyFor€')}</Trans></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="scroll-box">
                                                        <div className="scroll-box-inner">
                                                            <div className="scroll-box-img">
                                                                <img src="./images/sc-2.jpeg" className="img-fluid" alt=""/>
                                                            </div>
                                                            <div className="scroll-box-content">
                                                                <h5><Trans>{t('home:HamstringInjury')}</Trans></h5>
                                                                <p><Trans>{t('home:BuyFor€')}</Trans></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="scroll-box">
                                                        <div className="scroll-box-inner">
                                                            <div className="scroll-box-img">
                                                                <img src="./images/sc-3.jpeg" className="img-fluid" alt=""/>
                                                            </div>
                                                            <div className="scroll-box-content">
                                                                <h5><Trans>{t('home:AgilityPro')}</Trans></h5>
                                                                <p><Trans>{t('home:BuyFor€')}</Trans></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="scroll-box">
                                                        <div className="scroll-box-inner">
                                                            <div className="scroll-box-img">
                                                                <img src="./images/sc-4.jpeg" className="img-fluid" alt=""/>
                                                            </div>
                                                            <div className="scroll-box-content">
                                                                <h5><Trans>{t('home:WeightLoss')}</Trans></h5>
                                                                <p><Trans>{t('home:TrainFor€')}</Trans></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="scroll-box">
                                                        <div className="scroll-box-inner">
                                                            <div className="scroll-box-img">
                                                                <img src="./images/Frame627.png" className="img-fluid" alt=""/>
                                                            </div>
                                                            <div className="scroll-box-content">
                                                                <h5><Trans>{t('home:ACLInjury')}</Trans></h5>
                                                                <p><Trans>{t('home:TrainFor€')}</Trans></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-6 custom-height" data-aos="fade-up">
                                            <div className="shadow-seciton shadow-seciton-right">
                                                <div className="right-scroll-outer">
                                                    <div className="scroll-box-outer">
                                                        <div className="scroll-box">
                                                            <div className="scroll-box-inner">
                                                                <div className="scroll-box-img">
                                                                    <img src="./images/Frame-584.png" className="img-fluid" alt=""/>
                                                                </div>
                                                                <div className="scroll-box-content">
                                                                    <h5><Trans>{t('home:YogaProgram')}</Trans></h5>
                                                                    <p><Trans>{t('home:BuyFor€19')}</Trans>Comprar por 19,90€</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="scroll-box">
                                                            <div className="scroll-box-inner">
                                                                <div className="scroll-box-img">
                                                                    <img src="./images/sc-5.jpeg" className="img-fluid" alt=""/>
                                                                </div>
                                                                <div className="scroll-box-content">
                                                                    <h5><Trans>{t('home:HIITMuscle')}</Trans></h5>
                                                                    <p><Trans>{t('home:BuyFor€')}</Trans></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="scroll-box">
                                                            <div className="scroll-box-inner">
                                                                <div className="scroll-box-img">
                                                                    <img src="./images/sc-6.jpeg" className="img-fluid" alt=""/>
                                                                </div>
                                                                <div className="scroll-box-content">
                                                                    <h5><Trans>{t('home:StrengtheningBasics')}</Trans></h5>
                                                                    <p><Trans>{t('home:BuyFor€')}</Trans></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="scroll-box">
                                                            <div className="scroll-box-inner">
                                                                <div className="scroll-box-img">
                                                                    <img src="./images/sc-7.jpeg" className="img-fluid" alt=""/>
                                                                </div>
                                                                <div className="scroll-box-content">
                                                                    <h5><Trans>{t('home:MoveBetter')}</Trans></h5>
                                                                    <p><Trans>{t('home:BuyFor€')}</Trans></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="scroll-box">
                                                            <div className="scroll-box-inner">
                                                                <div className="scroll-box-img">
                                                                    <img src="./images/sc-8.jpeg" className="img-fluid" alt=""/>
                                                                </div>
                                                                <div className="scroll-box-content">
                                                                    <h5><Trans>{t('home:BeatDiabetes')}</Trans></h5>
                                                                    <p><Trans>{t('home:BuyFor€')}</Trans></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="scroll-box">
                                                            <div className="scroll-box-inner">
                                                                <div className="scroll-box-img">
                                                                    <img src="./images/Frame-5821.png" className="img-fluid" alt=""/>
                                                                </div>
                                                                <div className="scroll-box-content">
                                                                    <h5><Trans>{t('home:CrossfitGames')}</Trans></h5>
                                                                    <p><Trans>{t('home:BuyFor€')}</Trans></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="program-right">
                                        <div className="program-img">
                                            <img src="./images/Listados.png" className="img-fluid" alt=""/>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className="faq-section" data-aos="fade-up">
                    <div className="container">
                        <div className="faq-inner">

                            <div className="section-title section-title-white text-center" data-aos="fade-up">
                                <h2>Faq´s</h2>
                            </div>

                            <div className="faq-outer">
                                <div id="accordion">

                                    <div className="card" data-aos="fade-up">
                                        <div className="card-header" id="heading1">
                                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                                <Trans>{t('home:WhatIs')}</Trans>
                                                </button>
                                        </div>

                                        <div id="collapse1" className="collapse show" aria-labelledby="heading1" data-parent="#accordion">
                                            <div className="card-body">
                                                <p><Trans>{t('home:DownloadingTheApp')}</Trans></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" data-aos="fade-up">
                                        <div className="card-header" id="heading2">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                                <Trans>{t('home:CanIReceive')}</Trans>
                                                </button>
                                        </div>
                                        <div id="collapse2" className="collapse" aria-labelledby="heading2" data-parent="#accordion">
                                            <div className="card-body">
                                                <p><Trans>{t('home:OfCourse')}</Trans></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" data-aos="fade-up">
                                        <div className="card-header" id="heading3">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                                <Trans>{t('home:HowDoes')}</Trans>
                                                </button>
                                        </div>
                                        <div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#accordion">
                                            <div className="card-body">
                                                <p><Trans>{t('home:TheVirtual')}</Trans></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" data-aos="fade-up">
                                        <div className="card-header" id="heading4">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                                <Trans>{t('home:CanIUse')}</Trans>
                                                </button>
                                        </div>
                                        <div id="collapse4" className="collapse" aria-labelledby="heading4" data-parent="#accordion">
                                            <div className="card-body">
                                                <p><Trans>{t('home:IfYouDon')}</Trans></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" data-aos="fade-up">
                                        <div className="card-header" id="heading5">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                                <Trans>{t('home:WhatAreThe')}</Trans>
                                                </button>
                                        </div>
                                        <div id="collapse5" className="collapse" aria-labelledby="heading5" data-parent="#accordion">
                                            <div className="card-body">
                                                <p><Trans>{t('home:YouCan')}</Trans></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" data-aos="fade-up">
                                        <div className="card-header" id="heading6">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                                <Trans>{t('home:CanIUseThe')}</Trans>
                                                </button>
                                        </div>
                                        <div id="collapse6" className="collapse" aria-labelledby="heading6" data-parent="#accordion">
                                            <div className="card-body">
                                                <p><Trans>{t('home:RememberThat')}</Trans></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" data-aos="fade-up">
                                        <div className="card-header" id="heading7">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                                <Trans>{t('home:DoesSoloPerformance')}</Trans>
                                                </button>
                                        </div>
                                        <div id="collapse7" className="collapse" aria-labelledby="heading7" data-parent="#accordion">
                                            <div className="card-body">
                                                <p><Trans>{t('home:SoloPerformanceOffers')}</Trans></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card" data-aos="fade-up">
                                        <div className="card-header" id="heading8">
                                                <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                                <Trans>{t('home:HowDoesTheTraining')}</Trans>
                                                </button>
                                        </div>
                                        <div id="collapse8" className="collapse" aria-labelledby="heading8" data-parent="#accordion">
                                            <div className="card-body">
                                                <p><Trans>{t('home:Copyright')}</Trans></p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='gpc2o8vLqyg' onClose={() => this.setState({isOpen: false})} autoplay={true}/>
            <Footer/>
            </>
        )
    }

}


$(document).ready(function(){

    $("header ul.navbar-nav li a").click(function(){
        if($('.navbar-collapse').hasClass('show')){
            $('.navbar-collapse').removeClass("show");
            $('.navbar-toggler').addClass("collapsed");
            $('.navbar-toggler').attr("aria-expanded","false");
        }else{
    $('.navbar-collapse').addClass("hide");
    }
});

});

export default withRouter(withTranslation('home')(Home));